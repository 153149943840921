// Colors
$lun-color-black: #000000;
$lun-color-black-05: rgb(249, 250, 251);
$lun-color-black-10: rgb(243, 244, 246);
$lun-color-black-20: rgb(229, 231, 235);
$lun-color-black-40: rgb(156, 163, 175);
$lun-color-black-60: rgb(75, 85, 99);
$lun-color-black-80: rgb(31, 41, 55);
$lun-color-blue-05: rgb(239 246 255);
$lun-color-white: #ffffff;
$lun-color-scrim-bg: rgba($lun-color-black, 0.25);

// Spacing
$lun-space-2xs: 6px;
$lun-space-xs: 8px;
$lun-space-sm: 12px;
$lun-space-md: 16px;
$lun-space-lg: 20px;
$lun-space-xl: 32px;
$lun-space-2xl: 64px;

// Breakpoints
$lun-breakpoint-sm: 640px;
$lun-breakpoint-md: 768px;
$lun-breakpoint-lg: 1024px;

// Typography
// rt: Every type size should be cleaned up to handle responsive type, all usage will need to be tested before changing

$lun-text-1: 16px;
$lun-text-2: 14px;
$lun-text-3: 12px;
$lun-text-4: 10px;
$lun-title-4: 18px;

// Border radius
$lun-radius-md: 6px;
$lun-radius-half: 999px;

// Z-index
$lun-z-day-hover: 10;
$lun-z-res-wrapper: 11;
$lun-z-date-number: 12;
