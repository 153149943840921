// rt: should better match the email template styling
$space-2xs: 5px;
$space-xs: 10px;
$text-line-base: 1.5em;

.component {
  *:first-child {
    margin-top: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3 {
    margin-top: $space-xs;
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  p {
    margin-top: $space-xs;
    line-height: $text-line-base;
  }

  ol,
  ul {
    margin-top: $space-2xs;
    list-style-position: outside;
    list-style-type: disc;
    padding-inline-start: $space-xs;

    li {
      margin-top: $space-2xs;
      margin-left: $space-xs;
      line-height: $text-line-base;
    }
  }
} // .component
