@import "./Tokens.scss";

.component {
  display: flex;
  flex-direction: column;
  margin-top: $lun-space-xl;
}

.availabilityLabel {
  font-weight: 500;
}

.availabilityDescription {
  margin-bottom: $lun-space-md;
  color: $lun-color-black-80;
  font-size: $lun-text-2;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px $lun-space-md;
  font-weight: 500;
  cursor: pointer;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  &:first-child {
    padding-bottom: $lun-space-md;
    border-bottom: solid 1px $lun-color-black-10;
  }
}

.optionBase {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: $lun-space-md;
  height: $lun-space-md;
  margin-top: 2px;
  border-width: 1px;
  border-radius: 50%;
  cursor: pointer;
}

.optionSelected {
  border-color: transparent;
  background: $lun-color-black;
}

.optionDefault {
  border-color: $lun-color-black-40;
  background: $lun-color-white;
}

.optionActive {
  box-shadow: 0 0 0 2px $lun-color-black-05;
}

.optionDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $lun-color-white;
}

.optionLabel {
  display: block;
  margin-left: $lun-space-sm;
}

.radios {
  display: flex;
  flex-direction: column;
  gap: $lun-space-md;
  justify-content: space-between;
  padding: $lun-space-md 0px;
  border: solid 1px $lun-color-black-20;
  border-radius: $lun-radius-md;
}
