@import "./Tokens.scss";

$res-radius: 4px;
$res-height-sm: 20px;
$res-height-lg: 30px;
$res-gap-sm: 2px;
$res-gap-lg: 3px;

.reservationWrapper {
  position: absolute;
  top: 28px;
  left: 0px;
  z-index: $lun-z-res-wrapper;
  width: 100%;

  @media (min-width: $lun-breakpoint-md) {
    top: 35px;
  }
}

.reservation {
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: $res-height-sm;
  padding: 4px $lun-space-sm;
  background-color: $lun-color-white;
  color: $lun-color-black;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset,
    rgba(15, 15, 15, 0.1) 0px 1px 2px;
  outline: none;
  font-weight: 600;
  font-size: 11px;
  line-height: 1em;
  text-align: left;
  cursor: pointer;
  transition: background-color 60ms ease-in-out;

  @media (min-width: $lun-breakpoint-md) {
    height: $res-height-lg;
    font-size: 12px;
  }

  &.isHovered {
    background-color: $lun-color-blue-05;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: transparent;
  }
}

.label {
  display: block;
  min-height: 1em;
  white-space: nowrap;
}

.hasStart {
  border-top-left-radius: $res-radius;
  border-bottom-left-radius: $res-radius;
}

.hasEnd {
  border-top-right-radius: $res-radius;
  border-bottom-right-radius: $res-radius;
}

.stack-1 {
  top: 0px;
}

.stack-2 {
  top: calc(#{$res-height-sm} + #{$res-gap-sm});

  @media (min-width: $lun-breakpoint-md) {
    top: calc(#{$res-height-lg} + #{$res-gap-lg});
  }
}

.stack-3 {
  top: calc((#{$res-height-sm} + #{$res-gap-sm}) * 2);

  @media (min-width: $lun-breakpoint-md) {
    top: calc((#{$res-height-lg} + #{$res-gap-lg}) * 2);
  }
}

.span-1 {
  width: 100%;
}

.span-2 {
  width: calc(200% + 1px);
}

.span-3 {
  width: calc(300% + 2px);
}

.span-4 {
  width: calc(400% + 3px);
}

.span-5 {
  width: calc(500% + 4px);
}

.span-6 {
  width: calc(600% + 5px);
}

.span-7 {
  width: calc(700% + 6px);
}

.disabled {
  pointer-events: none;
}
