$dot-size-sm: 6px;
$dot-size-md: 8px;

.dots {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: calc($dot-size-md / 2);

  .dot {
    width: $dot-size-md;
    height: $dot-size-md;
    border-radius: 999px;
    background: #fff;
    opacity: 0;
    -moz-transform: rotate(
      0.1deg
    ); // rt: Is this needed? Maybe fixes small rendering bug in FF;
    animation: dot-animation-1 1.3s infinite ease-in;

    &:nth-child(2) {
      animation-name: dot-animation-2;
    }

    &:nth-child(3) {
      animation-name: dot-animation-3;
    }
  }

  &.small {
    column-gap: calc($dot-size-sm / 2);

    .dot {
      width: $dot-size-sm;
      height: $dot-size-sm;
    }
  }

  &.inverse .dot {
    background: #000;
  }
}

@keyframes dot-animation-1 {
  0%,
  70%,
  100% {
    opacity: 0.2;
  }

  30% {
    opacity: 1;
  }
}

@keyframes dot-animation-2 {
  0%,
  15%,
  85%,
  100% {
    opacity: 0.2;
  }

  45% {
    opacity: 1;
  }
}

@keyframes dot-animation-3 {
  0%,
  30%,
  100% {
    opacity: 0.2;
  }

  60% {
    opacity: 1;
  }
}
