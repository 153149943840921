@import "./Tokens.scss";

.dialog {
  position: relative;
  z-index: $lun-z-res-wrapper;
  opacity: 0.3;
}

.scrim {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: $lun-color-scrim-bg;
  opacity: 0;
  pointer-events: none;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  transition-property: opacity;

  &.isOpen {
    opacity: 1;
    pointer-events: all;
  }
}

.contentWrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
}

.contentOutterWrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  max-width: 100%;
  pointer-events: none;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  transition-property: transform;
  transform: translateX(100%);

  @media (min-width: $lun-breakpoint-sm) {
    padding-left: 40px;
  }

  &.isOpen {
    transform: translateX(0%);
  }
}

.contentInnerWrapper {
  width: 100%;
  max-width: 448px;
  pointer-events: auto;
}

.contentInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $lun-color-white;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);

  > :not([hidden]) ~ :not([hidden]) {
    border-color: rgb(229 231 235);
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 0px;
  }
}

.contentScroll {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  overflow-y: scroll;
  width: 400px;
  max-width: 100%;
  min-height: 0px;
}

.contentHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px $lun-space-md;

  @media (min-width: $lun-breakpoint-sm) {
    padding: 24px;
  }
}

.contentCloseWrapper {
  display: flex;
  align-items: center;
  height: 28px;
}

.contentCloseButton {
  border-radius: $lun-radius-md;
  background: $lun-color-white;
  color: $lun-color-black;
}

.contentCloseIcon {
  width: 24px;
  height: 24px;
  color: $lun-color-black;
}

.contentBody {
  position: relative;
  flex: 1 1 0%;
}

.controlsWrapper {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: $lun-space-md;
}

.buttonSubmit {
  margin-left: $lun-space-md;
}

.buttonInactive {
  opacity: 0.7;
}
