@import "./Tokens.scss";

.calendarWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: solid 1px $lun-color-black-20;
}

.calendarHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: $lun-space-md 24px;
  border-bottom: solid 1px $lun-color-black-20;
}

.calendarHeaderName {
  color: $lun-color-black;
  font-weight: 600;
  font-size: $lun-text-1;
}

.calendarHeaderMeta {
  color: $lun-color-black-60;
  font-weight: 400;
  font-size: $lun-text-3;
}

.calendarHeaderDate {
  color: $lun-color-black;
  font-weight: 600;
  font-size: $lun-title-4;
}

.calendarNavWrapper {
  display: grid;
  grid-template-columns: auto auto;
  justify-self: end;
  align-items: center;
  column-gap: $lun-space-md;
}

.calendarNavInner {
  display: flex;
  align-items: center;

  @media (min-width: $lun-breakpoint-md) {
    align-items: stretch;
  }
}

.monthButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $lun-space-xs;
  padding-right: $lun-space-md;
  padding-bottom: $lun-space-xs;
  padding-left: $lun-space-md;
  border: solid 1px $lun-color-black-20;
  background-color: $lun-color-white;
  color: $lun-color-black;
  font-weight: 500;
  font-size: $lun-text-2;

  @media (min-width: $lun-breakpoint-md) {
    padding-right: $lun-space-xs;
    padding-left: $lun-space-xs;

    &:hover {
      background-color: $lun-color-black-05;
    }
  }
  &:hover {
    color: $lun-color-black;
  }
  &:focus {
    position: relative;
  }
}

.monthButtonPrev {
  border-right: 0;
  border-top-left-radius: $lun-radius-md;
  border-bottom-left-radius: $lun-radius-md;
}

.monthButtonNext {
  border-left: 0;
  border-top-right-radius: $lun-radius-md;
  border-bottom-right-radius: $lun-radius-md;
}

.monthButtonCurrent {
  display: none;
  border-right: 0;
  border-left: 0;
  color: $lun-color-black;

  @media (min-width: $lun-breakpoint-md) {
    display: block;
  }

  &:hover {
    background-color: $lun-color-black-05;
    color: $lun-color-black;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.buttonDivider {
  position: relative;
  width: 1px;
  height: $lun-space-lg;
  margin-right: -1px;
  margin-left: -1px;
  background-color: $lun-color-black-20;

  @media (min-width: $lun-breakpoint-md) {
    display: none;
  }
}

.monthGridWrapper {
  @media (min-width: $lun-breakpoint-lg) {
    display: flex;
    flex: auto;
    flex-direction: column;
  }

  // Hide hover state for date if a reservation is hovered on
  &.selectDisabled {
    .dayHoverable {
      &:before {
        display: none;
      }
    }
  }
}

.monthGrid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 1px;
  border-bottom: solid 1px $lun-color-black-20;
  color: $lun-color-black-60;
  font-weight: 600;
  font-size: $lun-text-3;
  line-height: 24px;
  text-align: center;

  @media (min-width: $lun-breakpoint-lg) {
    flex: none;
  }
}

.monthDayLabel {
  padding: $lun-space-xs 0;
}

.monthDayFullText {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border-width: 0;
  white-space: nowrap;

  @media (min-width: $lun-breakpoint-sm) {
    position: static;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    white-space: normal;
  }
}

.daysGridWrapper {
  position: relative;
  display: flex;
  color: $lun-color-black;
  font-size: $lun-text-4;
  line-height: 24px;

  @media (min-width: $lun-breakpoint-md) {
    font-size: $lun-text-3;
  }

  @media (min-width: $lun-breakpoint-lg) {
    flex: auto;
  }
}

.daysGridInner {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 1px;
  width: 100%;
  background-color: $lun-color-black-20;
  isolation: isolate;
}

.dayBase {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 96px;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: $lun-breakpoint-md) {
    height: 136px;
  }

  @media (min-width: $lun-breakpoint-lg) {
    align-items: flex-start;
  }
}

.dateNumber {
  z-index: $lun-z-date-number;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  border-radius: 50%;
  color: $lun-color-black;
  text-align: center;

  @media (min-width: $lun-breakpoint-md) {
    width: 24px;
    height: 24px;
    margin-top: $lun-space-xs;
    margin-left: $lun-space-xs;
  }
}

.dayDefault {
  background-color: $lun-color-white;
}

.dayInvalid {
  background-color: $lun-color-black-05;
  cursor: not-allowed;
}

.daySelected {
  background-color: $lun-color-blue-05;
}

.dayIsToday {
  font-weight: 600;
}

.dayInBetween {
  background-color: $lun-color-blue-05;

  .dateNumber {
    color: $lun-color-black;
  }
}

.dayHoverable {
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: $lun-z-day-hover;
    width: 100%;
    height: 100%;
    border: solid 4px transparent;
    pointer-events: none;
  }
}

.dayIsHovered {
  &:before {
    transition: background-color 30ms ease-in-out;

    @media (min-width: $lun-breakpoint-md) {
      background-color: $lun-color-blue-05;
    }
  }
}

.isLoading {
  opacity: 0;
}

.loader {
  position: absolute;
  top: 200px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}

.dateIsToday {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $lun-color-black;
  color: $lun-color-white !important;
  font-weight: 600;
}

.dateIsSelected {
  color: $lun-color-black;
}

.blocked {
  background-color: $lun-color-black-05;

  .dateNumber {
    text-decoration: line-through !important;
  }
}
