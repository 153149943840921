@import "./Tokens.scss";

.component {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-top: $lun-space-xl;
  padding: 4px;
  border: none;
  border: solid 1px $lun-color-black-20;
  border-radius: $lun-radius-half;
  background-color: $lun-color-black-05 !important;
  cursor: pointer;
  place-items: center;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: $lun-radius-half;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 50%;
  height: 100%;
  border-radius: $lun-radius-half;
  background: $lun-color-black;
  transition: transform 200ms ease-in-out 0s;
}

.buttonEnabled {
  transform: translateX(100%);
}

.textContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.text {
  display: block;
  padding-top: $lun-space-xs;
  padding-bottom: $lun-space-xs;
  color: $lun-color-white;
  font-weight: 500;
  font-size: $lun-text-1;
  text-align: center;
  transition: color 200ms ease-in-out 0s;
}

.textEnabled {
  color: $lun-color-black;
}
