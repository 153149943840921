.calendar {
  :global {
    .react-calendar {
      border: 0;
      width: 100%;
      font-family: inherit;

      button:disabled {
        background-color: transparent;
        pointer-events: none;
      }

      .react-calendar__tile:disabled {
        opacity: 0.35;
      }

      .react-calendar__month-view__days__day--weekend {
        color: inherit;
      }

      .react-calendar__tile--active {
        background-color: rgba(37, 99, 235, 1);
        color: white;
      }
    } // .react-calendar
  } // :global
} // .calendar

.blocked {
  text-decoration: line-through;
  color: rgb(220, 38, 38) !important;
}
