@import "./Tokens.scss";

.component {
  padding: $lun-space-md;

  @media (min-width: $lun-breakpoint-sm) {
    padding: 24px;
  }
}

.dateWrapper {
  display: flex;
  flex-direction: column;
}

.dateLabel {
  margin-bottom: $lun-space-xs;
  font-weight: 500;
}

.dateInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateInput {
  flex-grow: 1;
  padding: $lun-space-xs $lun-space-md;
  border: solid 1px $lun-color-black-20 !important;
  border-radius: $lun-radius-md !important;
  background: $lun-color-white;
  color: $lun-color-black;

  &:focus {
    outline-color: $lun-color-black;
  }
}

.dateArrow {
  width: 24px;
  height: 24px;
  margin-right: $lun-space-sm;
  margin-left: $lun-space-sm;
}
